import { render, staticRenderFns } from "./TeCommonTitleSectionBold.vue?vue&type=template&id=14744655&scoped=true&lang=pug"
var script = {}
import style0 from "./TeCommonTitleSectionBold.vue?vue&type=style&index=0&id=14744655&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14744655",
  null
  
)

export default component.exports