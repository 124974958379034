import { render, staticRenderFns } from "./TeTopVoice.vue?vue&type=template&id=2adcb73f&scoped=true&lang=pug"
import script from "./TeTopVoice.vue?vue&type=script&lang=js"
export * from "./TeTopVoice.vue?vue&type=script&lang=js"
import style0 from "./TeTopVoice.vue?vue&type=style&index=0&id=2adcb73f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adcb73f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeCommonTitleSectionBold: require('/vercel/path0/components/Te/TeCommon/Title/TeCommonTitleSectionBold/TeCommonTitleSectionBold.vue').default,TeCommonTitleSectionBoldSub: require('/vercel/path0/components/Te/TeCommon/Title/TeCommonTitleSectionBoldSub.vue').default,TeTopReviewsTwitter: require('/vercel/path0/components/Te/TeTop/TeTopReviewsTwitter/TeTopReviewsTwitter.vue').default,TeCommonRibbon: require('/vercel/path0/components/Te/TeCommon/Ribbon/TeCommonRibbon/TeCommonRibbon.vue').default})
