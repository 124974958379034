import { render, staticRenderFns } from "./TeCommonTitleSectionBoldSub.vue?vue&type=template&id=12584ed3&scoped=true&lang=pug"
var script = {}
import style0 from "./TeCommonTitleSectionBoldSub.vue?vue&type=style&index=0&id=12584ed3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12584ed3",
  null
  
)

export default component.exports