
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: false,
        dots: true,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 500,
        swipe: true,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
    }
  },
}
